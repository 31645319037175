import React from "react"
import { Helmet } from "react-helmet"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// @material-ui/icons
import TabletMacSharp from "@material-ui/icons/TabletMacSharp"
import Code from "@material-ui/icons/Code"
import Commute from "@material-ui/icons/Commute"
// React icons
import { FaYoutube, FaEnvelope, FaMedium } from "react-icons/fa"
// core components
import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import NavPills from "components/NavPills/NavPills.jsx"
import Parallax from "components/Parallax/Parallax.jsx"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx"

import profile from "assets/img/faces/matthew.jpeg"

import ReactPlayer from "react-player/youtube"

import profilePageStyle from "assets/jss/material-kit-react/views/profilePage.jsx"

class ProfilePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    )
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery)
    return (
      <div style={{ background: "#121212" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Matthew Brown - Technology | Code | Cars</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <Header
          color="transparent"
          brand=""
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "#FFFFFF",
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/profile-bg-2.jpg")} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <div className={classes.profile}>
                    <div>
                      <img src={profile} alt="..." className={imageClasses} />
                    </div>
                    <div className={classes.name}>
                      <h3 className={classes.title}>Matthew Brown</h3>
                      <h6>Technology | Code | Cars</h6>
                      <Button
                        href="https://www.youtube.com/channel/UCnLCTX6xmVJ00d0xNKPL2Xw"
                        target="_blank"
                        justIcon
                        link
                        className={classes.margin5}
                      >
                        <FaYoutube />
                      </Button>
                      <Button
                        href="https://matthew-brown-js.medium.com/"
                        target="_blank"
                        justIcon
                        link
                        className={classes.margin5}
                      >
                        <FaMedium />
                      </Button>
                      <Button
                        href="mailto:matthew.brown.js@gmail.com"
                        target="_blank"
                        justIcon
                        link
                        className={classes.margin5}
                      >
                        <FaEnvelope />
                      </Button>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.description}>
                <p>
                  I am a software engineer based in Kansas City. I am passionate
                  about all things technology, building great software, and
                  cars. When I'm not building software applications my hobbies
                  include creating content for YouTube and Medium and keeping up
                  with the latest tech products from Apple.{" "}
                </p>
              </div>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                  <NavPills
                    alignCenter
                    color="primary"
                    tabs={[
                      {
                        tabButton: "Code",
                        tabIcon: Code,
                        tabContent: (
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6}>
                              <Card>
                                <CardHeader color="primary">
                                  <h6>Build a Blog With Next.JS</h6>
                                </CardHeader>
                                <CardBody>
                                  A quick introduction and guide to building
                                  your own blog site with Next.JS
                                </CardBody>
                                <CardFooter>
                                  <Button
                                    href="https://medium.com/geekculture/build-a-blog-with-next-js-2315d9683ef"
                                    target="_blank"
                                    fullWidth={true}
                                  >
                                    Read
                                  </Button>
                                </CardFooter>
                              </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <Card>
                                <CardHeader color="primary">
                                  <h6>
                                    How to Build Awesome Tables With React Table
                                  </h6>
                                </CardHeader>
                                <CardBody>
                                  React Tables is the easy way to build tables
                                  with sorting, paging, CSV exports, and more.
                                </CardBody>
                                <CardFooter>
                                  <Button
                                    href="https://medium.com/swlh/how-to-build-awesome-tables-with-react-table-963955fd9262"
                                    target="_blank"
                                    fullWidth={true}
                                  >
                                    Read
                                  </Button>
                                </CardFooter>
                              </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <Card>
                                <CardHeader color="primary">
                                  <h6>
                                    Clean Up Your React Code With Custom Hooks
                                  </h6>
                                </CardHeader>
                                <CardBody>
                                  Clean up functional components with custom
                                  hooks.
                                </CardBody>
                                <CardFooter>
                                  <Button
                                    href="https://medium.com/swlh/clean-up-your-react-code-with-custom-hooks-57f7f4410593"
                                    target="_blank"
                                    fullWidth={true}
                                  >
                                    Read
                                  </Button>
                                </CardFooter>
                              </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <Card>
                                <CardHeader color="primary">
                                  <h6>
                                    How to Create a Serverless NodeJS REST API
                                  </h6>
                                </CardHeader>
                                <CardBody>
                                  A guide for creating a REST API with the
                                  Serverless Framework and DynamoDB
                                </CardBody>
                                <CardFooter>
                                  <Button
                                    href="https://medium.com/swlh/how-to-create-a-serverless-nodejs-rest-api-903e16d80fea"
                                    target="_blank"
                                    fullWidth={true}
                                  >
                                    Read
                                  </Button>
                                </CardFooter>
                              </Card>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6}>
                              <Card>
                                <CardHeader color="primary">
                                  <h6>
                                    Simple State Management With MobX and React
                                  </h6>
                                </CardHeader>
                                <CardBody>
                                  A look at how to use MobX to manage state in
                                  your React applications.
                                </CardBody>
                                <CardFooter>
                                  <Button
                                    href="https://medium.com/swlh/simple-state-management-with-mobx-and-react-c2c7816f8833"
                                    target="_blank"
                                    fullWidth={true}
                                  >
                                    Read
                                  </Button>
                                </CardFooter>
                              </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <Card>
                                <CardHeader color="primary">
                                  <h6>
                                    How to Create a Serverless NodeJS REST API
                                  </h6>
                                </CardHeader>
                                <CardBody>
                                  A guide for creating a REST API with the
                                  Serverless Framework and DynamoDB
                                </CardBody>
                                <CardFooter>
                                  <Button
                                    href="https://medium.com/swlh/how-to-create-a-serverless-nodejs-rest-api-903e16d80fea"
                                    target="_blank"
                                    fullWidth={true}
                                  >
                                    Read
                                  </Button>
                                </CardFooter>
                              </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <Card>
                                <CardHeader color="primary">
                                  <h6>
                                    Build Offline Capable Apps With React Native
                                    and Redux Offline
                                  </h6>
                                </CardHeader>
                                <CardBody>
                                  A guide for building a React Native image
                                  upload app with offline capabilities.
                                </CardBody>
                                <CardFooter>
                                  <Button
                                    href="https://medium.com/swlh/build-offline-capable-apps-with-react-native-and-redux-offline-bcbffeeed857"
                                    target="_blank"
                                    fullWidth={true}
                                  >
                                    Read
                                  </Button>
                                </CardFooter>
                              </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <Card>
                                <CardHeader color="primary">
                                  <h6>How to Use OpenLayers Maps in React</h6>
                                </CardHeader>
                                <CardBody>Let us map it out…</CardBody>
                                <CardFooter>
                                  <Button
                                    href="https://medium.com/swlh/how-to-incorporate-openlayers-maps-into-react-65b411985744"
                                    target="_blank"
                                    fullWidth={true}
                                  >
                                    Read
                                  </Button>
                                </CardFooter>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        ),
                      },
                      {
                        tabButton: "Technology",
                        tabIcon: TabletMacSharp,
                        tabContent: (
                          <GridContainer justify="center" alignItems="center">
                            <GridItem xs={12} sm={12} md={12}>
                              <ReactPlayer
                                width="100%"
                                style={{ margin: 25 }}
                                url="youtube.com/watch?v=BggEcqvdy7k"
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <ReactPlayer
                                width="100%"
                                style={{ margin: 25 }}
                                url="https://www.youtube.com/watch?v=VdADOaqiBhM"
                              />
                            </GridItem>
                          </GridContainer>
                        ),
                      },
                      {
                        tabButton: "Cars",
                        tabIcon: Commute,
                        tabContent: (
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                              <ReactPlayer
                                width="100%"
                                style={{ margin: 25 }}
                                url="https://www.youtube.com/watch?v=3lgUn78--o4"
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <ReactPlayer
                                width="100%"
                                style={{ margin: 25 }}
                                url="https://www.youtube.com/watch?v=lNvoCdZYluU"
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <ReactPlayer
                                width="100%"
                                style={{ margin: 25 }}
                                url="https://www.youtube.com/watch?v=0qq7amvLuZQ"
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <ReactPlayer
                                width="100%"
                                style={{ margin: 25 }}
                                url="https://www.youtube.com/watch?v=W45XeA4B3ko"
                              />
                            </GridItem>
                          </GridContainer>
                        ),
                      },
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default withStyles(profilePageStyle)(ProfilePage)
